import { DateTime } from "luxon";
import { defineStore } from "pinia";

import { RequestError } from "@/types/requests.ts";
import { useToast } from "@/composables/useToast";
import type { UpdateByProduct } from "@/types/adsSummary";
import type { FiltersStoreType, Product } from "@/types/filters";
import {
  createUserFilterApi,
  getUserFiltersApi,
  deleteUserFilterApi,
  setFavoriteFilterApi,
} from "@/api/filters";
import { getRefreshDate } from "@/api";

const { error } = useToast();

export const useFiltersStore = defineStore("filtersStore", {
  state: (): FiltersStoreType => ({
    search: "",
    brands: [],
    channels: [],
    amzStatus: "",
    product: null,
    loading: true,
    productSearch: [],
    productStatus: "",
    searchBySeller: "",
    simpleSearch: false,
    searchLabel: "Product",
    searchPlaceholder: "Search by Name, ASIN or SKU",
    granularity: "MONTH",
    isOpenSidebar: false,
    parsedDateToMonths: [],
    currentSelectedProduct: null,
    picker: {
      since: "",
      until: "",
    },
    kpisPicker: {
      since: "",
      until: "",
    },
    date: [
      DateTime.now().minus({ month: 12 }).toUTC().toISO(),
      DateTime.now().toUTC().toISO(),
    ] as [string, string],
    updatedBrandBySelectValue: false,
    updatedChannelBySelectValue: false,
    hideOutStock: false,
    userFilters: [],
    isUpdateUserFilters: false,
    isDeleteUserFilters: false,
    refreshDate: "",
  }),

  actions: {
    async getRefreshDate() {
      try {
        const { data } = await getRefreshDate();
        this.refreshDate = data;
      } catch (err) {
        const { response } = err as RequestError;
        error(response.data.message);
      }
    },

    async getUserFilters() {
      this.isUpdateUserFilters = true;
      try {
        const { data } = await getUserFiltersApi();
        this.userFilters = data;
      } catch (err) {
        const { response } = err as RequestError;
        error(response.data.message);
      } finally {
        this.isUpdateUserFilters = false;
      }
    },

    async createUserFilter(data: any, callback?: () => void) {
      try {
        await createUserFilterApi(data);
        callback && callback();
        await this.getUserFilters();
      } catch (err) {
        const { response } = err as RequestError;
        error(response.data.message);
      }
    },

    async deleteUserFilter(id: string, callback?: () => void) {
      this.isDeleteUserFilters = true;
      try {
        await deleteUserFilterApi(id);
        callback && callback();
        await this.getUserFilters();
      } catch (err) {
        const { response } = err as RequestError;
        error(response.data.message);
      } finally {
        this.isDeleteUserFilters = false;
      }
    },

    async setFavoriteFilter(id: string, favorite: boolean) {
      try {
        await setFavoriteFilterApi(id, favorite);
      } catch (err) {
        const { response } = err as RequestError;
        error(response.data.message);
        await this.getUserFilters();
      }
    },

    updateLoading(loading: boolean) {
      this.loading = loading;
    },

    updateHideOutStock(value: boolean) {
      this.hideOutStock = value;
    },

    updateDate(date: [string, string]) {
      this.date = date;
    },

    updateSearchInStore(search: string) {
      this.search = search;
    },

    updateSearchBySeller(search: string) {
      this.searchBySeller = search;
    },

    updatedBrandBySelect(value: boolean) {
      this.updatedBrandBySelectValue = value;
    },

    updatedChannelBySelect(value: boolean) {
      this.updatedChannelBySelectValue = value;
    },

    updateBrandsInStore(brands: string[]) {
      this.brands = brands || [];
    },

    updateParsedDateToMonths(date: string[]) {
      this.parsedDateToMonths = date;
    },

    updateProductStatus(status: "active" | "inactive" | "") {
      this.productStatus = status;
    },

    updateAmzStatus(status: "active" | "inactive" | "") {
      this.amzStatus = status;
    },

    updateSimpleSearchInStore(
      value: boolean,
      label?: string,
      placeholder?: string,
    ) {
      this.simpleSearch = value;
      this.searchLabel = label || "Product";
      this.searchPlaceholder = placeholder || "Search by Name, ASIN or SKU";
    },

    updateFilterIsOpenSidebar(value: boolean) {
      this.isOpenSidebar = value;
    },

    updateProductAfterSearch(product: Product) {
      this.product = product;
    },

    updatePicker(date: { since: string; until: string }) {
      this.picker = date;
    },

    updateChannelsInStore(channels: string[] | number[]) {
      this.channels = channels;
    },

    updateGranularityInStore(granularity: "MONTH" | "WEEK" | "DAY") {
      this.granularity = granularity;
    },

    updateProductSearchInStore(search: string[] | number[]) {
      this.productSearch = search;
    },

    updateKPIsPickerInStore(date: { since: string; until: string }) {
      this.kpisPicker = date;
    },

    updateCurrentSelectedProductInStore(product: UpdateByProduct | null) {
      this.currentSelectedProduct = product;
    },
  },
});
