import { createRouter, createWebHistory } from "vue-router";

import { RouteNames } from "@/types/router";
import { ACCESS_TOKEN } from "@/utils/axios";

import { hasPermission, checkInsightful } from "@/utils/permissions";
import type { PermissionsType } from "@/types/router";
import {
  RoutePathsWithPermissions,
  ArrayOfPriorityRoutes,
} from "@/types/router";
import { useAbortController } from "@/store/abortController";

import { useAuthStore } from "@/store/auth";
import modules from "./modules.js";
import { useFiltersStore } from "@/store/filters";
import { handleNavigation } from "@/utils/routing.ts";

const router = createRouter({
  history: createWebHistory(),
  routes: modules,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const abortControlelr = useAbortController();

  // Abort all requests when navigating away from the page
  if (from.path !== to.path && from.path !== "/") {
    abortControlelr.abortAllRequests();
  }

  if (
    to.path !== "/login" &&
    to.path !== "/password-forgot" &&
    to.path !== "/reset-password"
  ) {
    await authStore.getUser();
  } else return next();

  handleNavigation(from, to, next, authStore);
});

export default router;
